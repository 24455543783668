

















































































































































































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import BaseLayout from '@/shared/components/BaseLayout.vue'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'
import Site from '@/api/models/Site'
import AccountSettings from '@/api/models/AccountSettings'

@Component({
    components: {
        BaseLayout
    }
})
export default class Accounts extends Vue {
    @Getter('connection')
    connection!: Connection

    @Getter('accounts')
    accounts!: Array<Account>

    @Getter('sites')
    sites!: Array<Site>

    @Action('selectAccount')
    selectAccount!: (account: Account) => Promise<void>

    @Action('createAccount')
    createAccountAction!: (account: Account) => Promise<void>

    @Action('updateAccount')
    updateAccount!: (account: Account) => Promise<void>

    @Action('updateAccountSettings')
    updateAccountSettings!: (settings: AccountSettings) => Promise<void>

    @Action('updateApiKey')
    updateApiKey!: (apiKey: string) => Promise<void>

    private selectedSite: Site = { code: '', name: '' }
    private clientId = ''
    private ozonKey = ''

    private showPopup = false

    private apiKeyEdit = false
    private apiKey = ''
    private popupAction = ''
    private editedAccount: Account | undefined
    private errorMsg = ''

    created (): void {
        this.apiKey = this.connection.apiKey
    }

    get sitesList (): Array<Site> {
        const issetSites: Array<string> = []
        this.accounts.forEach((account: Account) => {
            issetSites.push(account.site)
        })

        if (this.popupAction === 'editAccount') {
            return this.sites
        }

        return this.sites.filter((site: Site) => issetSites.find((siteCode: string) => site.code === siteCode) === undefined)
    }

    private async editAccount () {
        try {
            await this.updateAccount({
                ozonClientId: this.clientId,
                ozonKey: this.ozonKey,
                site: this.selectedSite.code,
                id: this.editedAccount?.id
            })

            this.editedAccount = undefined
            this.showPopup = false
            this.errorMsg = ''
        } catch (err) {
            if (
                Array.isArray(err.response.data.details) &&
                err.response.data.details.length > 0
            ) {
                this.errorMsg = err.response.data.details[0]
            } else {
                this.errorMsg = err.response.data.message ?? err
            }
        }
    }

    private async updateAccountActivity (account: Account): Promise<void> {
        await this.selectAccount(account)

        const settings = account.settings
        if (settings) {
            const updateRequest = JSON.parse(JSON.stringify(account.settings))
            updateRequest.isActive = !updateRequest.isActive

            try {
                await this.updateAccountSettings(updateRequest)
                settings.isActive = !settings.isActive
                this.errorMsg = ''
            } catch (err) {
                if (
                    Array.isArray(err.response.data.details) &&
                    err.response.data.details.length > 0
                ) {
                    this.errorMsg = err.response.data.details[0]
                } else {
                    this.errorMsg = err.response.data.message ?? err
                }
            }
        }
    }

    private async createAccount (): Promise<void> {
        try {
            await this.createAccountAction({
                ozonClientId: this.clientId,
                ozonKey: this.ozonKey,
                site: this.selectedSite.code
            })
            await this.$router.push({ name: 'account_install' })
        } catch (err) {
            this.showPopup = false

            if (
                Array.isArray(err.response.data.details) &&
                err.response.data.details.length > 0
            ) {
                this.errorMsg = err.response.data.details[0]
            } else {
                this.errorMsg = err.response.data.message ?? err
            }
        }
    }

    private getSiteName (siteCode: string): string {
        return this.sites.find((site: Site) => site.code === siteCode)?.name ?? ''
    }

    private prepareEditAccount (account: Account): void {
        this.showPopup = true
        this.popupAction = 'editAccount'
        this.clientId = account.ozonClientId
        this.ozonKey = account.ozonKey

        const site = this.sites.find(
            value => {
                return value.code === account.site
            }
        )

        if (site !== undefined) {
            this.selectedSite = { name: site.name, code: site.code }
        }

        this.editedAccount = account
    }

    private switchAccount (account: Account): void {
        this.selectAccount(account)
        this.$router.push({ name: 'account_statuses' })
    }

    private closeAlert () {
        this.errorMsg = ''
    }
}
